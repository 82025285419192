import React, { Component } from "react";

class ContactMelomate extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
            isSent: null
        };
    }
    render(){

        if(this.state.isSent === true) {

            return <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Vielen Dank!</h2>
                                <p className="description">Wir werden uns in kürze bei Ihnen melden.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        } else if(this.state.isSent === false) {

            return <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Oops!</h2>
                                <p className="description">Leider ist bei der Übermittlung etwas schief gelaufen. Bitte prüfen Sie alle Eingaben!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Kontakt</h2>
                            </div>
                            <div className="form-wrapper">
                                <label htmlFor="item01">
                                    <input
                                        type="text"
                                        name="name"
                                        id="item01"
                                        value={this.state.rnName}
                                        onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                        placeholder="Ihr Name *"
                                    />
                                </label>

                                <label htmlFor="item02">
                                    <input
                                        type="text"
                                        name="email"
                                        id="item02"
                                        value={this.state.rnEmail}
                                        onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                        placeholder="Ihre E-Mail *"
                                    />
                                </label>

                                <label htmlFor="item03">
                                    <input
                                        type="text"
                                        name="subject"
                                        id="item03"
                                        value={this.state.rnSubject}
                                        onChange={(e)=>{this.setState({rnSubject: e.target.value});}}
                                        placeholder="Betreff"
                                    />
                                </label>
                                <label htmlFor="item04">
                                    <textarea
                                        type="text"
                                        id="item04"
                                        name="message"
                                        value={this.state.rnMessage}
                                        onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                        placeholder="Ihre Nachricht"
                                    />
                                </label>
                                <button onClick={() => {
                                        fetch('https://melomate.de/utils/contact.php', {
                                            method: 'POST',
                                            body: JSON.stringify({
                                                name: this.state.rnName,
                                                mail: this.state.rnEmail,
                                                subject: this.state.rnSubject,
                                                message: this.state.rnMessage
                                            })
                                        }).then((data) => {
                                            if(data.status === 200) {
                                                console.log('Request success: ', data);

                                                this.setState({isSent: true})
                                                return;
                                            }
                                            console.log('Request failure');

                                            this.setState({isSent: false})
                                        }).catch((error) => {
                                            console.log('Request failure: ', error);

                                            this.setState({isSent: false})
                                        });
                                }} className="rn-button-style--2 btn-solid">Abschicken</button>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/about/about-6.jpg" alt="trydo"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactMelomate;