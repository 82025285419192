import React, { Component } from "react";
import PageHelmet from "../component/common/HelmetMelomate";
import { FiHeadphones } from "react-icons/fi";
import { FiMail } from "react-icons/fi";
import { FiChevronUp } from "react-icons/fi";
import ContactMelomate from "../elements/contact/ContactMelomate";
import ScrollToTop from 'react-scroll-up';
import Header from "../component/header/HeaderMelomate";
import Footer from "../component/footer/FooterMelomate";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

class Contact extends Component{
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Kontakt' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17"  data-black-overlay="6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Kontakt</h2>
                                    <p>Wir erstellen Ihnen gerne ein individuelles Angebot</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}


                {/* Start Contact Top Area  */}
                <div className="rn-contact-top-area ptb--120 bg_color--5">
                    <div className="container">

                        <div className="row">
                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiHeadphones />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Kontaktieren Sie uns telefonisch</h4>
                                        <p><a href="tel:+4925539193983">02553/9193983</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/* Start Single Address  */}
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                                <div className="rn-address">
                                    <div className="icon">
                                        <FiMail />
                                    </div>
                                    <div className="inner">
                                        <h4 className="title">Email</h4>
                                        <p><a href="mailto:info@melomate.de">info@melomate.de</a></p>
                                    </div>
                                </div>
                            </div>
                            {/* End Single Address  */}

                            {/*/!* Start Single Address  *!/*/}
                            {/*<div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">*/}
                            {/*    <div className="rn-address">*/}
                            {/*        <div className="icon">*/}
                            {/*            <FiMapPin />*/}
                            {/*        </div>*/}
                            {/*        <div className="inner">*/}
                            {/*            <h4 className="title">Location</h4>*/}
                            {/*            <p>5678 Bangla Main Road, cities 580 <br /> GBnagla, example 54786</p>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            {/*/!* End Single Address  *!/*/}

                        </div>
                    </div>
                </div>
                {/* End Contact Top Area  */}

                {/* Start Contact Page Area  */}
                <div className="rn-contact-page ptb--120 bg_color--1">
                    <ContactMelomate />
                </div>
                {/* End Contact Page Area  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

                <Footer />

            </React.Fragment>
        )
    }
}
export default Contact