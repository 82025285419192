import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} | Melomate </title>
                    <meta name="description" content="Wir bieten Ihnen Lösungen, die nicht nur effizient, sondern auch smart sind. Gemeinsam definieren wir Wege, den digitalen Fortschritt in Ihr Unternehmen zu bringen, und entwickeln passende Software und Anwendungen, die Sie und Ihre Kunden überzeugen werden" />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
