import React ,{ Component }from "react";
import { FiMonitor , FiShoppingBag , FiLayers , FiSmartphone, FiPenTool } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiMonitor />,
        title: 'Ihre Webpräsenz',
        description: 'Erstellung einer auf individuellen Wünschen basierenden Website'
    },
    {
        icon: <FiShoppingBag />,
        title: 'Online Shopping',
        description: 'Magento 2-basierende Shop-Lösungen für Ihr Unternehmen'
    },
    {
        icon: <FiLayers />,
        title: 'Custom Solutions',
        description: 'Gerne beraten wir Sie bei individuellen Kundenwünschen'
    },
    {
        icon: <FiSmartphone />,
        title: 'Mobile Anwendungen',
        description: 'Programmierung nativer Apps plattformübergreifend und parallel für Android und iOS'
    },
    {
        icon: <FiPenTool />,
        title: 'UI & UX',
        description: 'Visuelle Gestaltung für frustfreie, leicht erlernbare und intuitiv zu bedienende Produkte'
    }
]


class ServiceMelomate extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);

        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            {/*<a href="/service-details">*/}
                            <div className="service service__style--2">
                                <div className="icon">
                                    {val.icon}
                                </div>
                                <div className="content" style={{
                                    minHeight: '10rem'
                                }}>
                                    <h3 className="title">{val.title}</h3>
                                    <p style={{
                                        fontSize: 16,
                                        lineHeight: 1.4
                                    }}>{val.description}</p>
                                </div>
                            </div>
                            {/*</a>*/}
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceMelomate;
