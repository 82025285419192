import React, { Component , Fragment } from "react";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import Slider from "react-slick";
import { slideSlick } from "../page-demo/script";
import ServiceList from "../elements/service/ServiceListMelomate";
import BlogContent from "../elements/blog/BlogContent";
import BrandMelomate from "../elements/BrandMelomate";
import PortfolioList from "../elements/portfolio/PortfolioList";
import Header from "../component/header/HeaderMelomate";
import FooterMelomate from "../component/footer/FooterMelomate";
import CallAction from "../elements/callaction/CallAction";
import Team from "../elements/Team";
import { FiCheck, FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/HelmetMelomate";
import ReactCardFlip from 'react-card-flip';
import AccordionMelomate from "../elements/AccordionMelomate";

const SlideList = [
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--17',
        category: '',
        title: 'Out of the Box',
        description: 'Prozesse optimieren und neue Maßstäbe setzen',
        buttonLink: '/contact'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--18',
        category: '',
        title: 'Entwicklung',
        description: 'Digitale Automation und Onlineshops',
        buttonLink: '/contact'
    },
    {
        textPosition: 'text-left',
        bgImage: 'bg_image--19',
        category: '',
        title: 'Branding',
        description: 'Gemeinsam Marken erschaffen, die Sie und Ihre Kunden lieben',
        buttonLink: '/contact'
    }
]

class Melomate extends Component{
    constructor () {
        super()
        this.state = {
            isOpen: false,
            isFlippedStates: [false, false, false, false],
        }
        this.openModal = this.openModal.bind(this)
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(e, currentElement, resetAll) {
        e.preventDefault();
        this.setState(prevState => {

            if(resetAll) {

                return ({ isFlippedStates: [false, false, false, false] })
            }

            let getFlippedStates = prevState.isFlippedStates;

            getFlippedStates[currentElement] = true;

            return ({ isFlippedStates: getFlippedStates })
        });
    }
    openModal () {
        this.setState({isOpen: true})
    }
    renderMeloCards (cardId) {
        return <div style={{
            padding: 5
        }}>
            <ReactCardFlip isFlipped={this.state.isFlippedStates[cardId]} flipDirection="horizontal">
                <div onMouseEnter={(element) => this.handleClick(element, cardId)}>
                    <img src={`/assets/images/melomate/cards/card${cardId}.png`} width="350px" alt="Melomate Project"/>
                </div>

                <div onMouseLeave={(element) => this.handleClick(element, cardId, true)}>
                    <img src={`/assets/images/melomate/cards/card${cardId}_content.png`} width="350px" alt="Melomate Project"/>
                </div>
            </ReactCardFlip>
        </div>
    }
    render(){

        var namesItemOne = [
            'Innovation',
            'Effizienz',
            'Out-of-the-Box-Denken'
        ];

        return(
            <Fragment>
                <Helmet pageTitle="Home" />
                <Header />
                {/* Start Slider Area   */}
                <div className="slider-wrapper">
                    <div className="slider-activation">
                        <Slider className="rn-slick-dot dot-light" {...slideSlick}>
                            {SlideList.map((value , index) => (
                                <div className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`} key={index} data-black-overlay="8">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className={`inner ${value.textPosition}`}>
                                                    {value.category ? <span>{value.category}</span> : ''}
                                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                                    {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-solid" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                {/* End Slider Area   */}

                <div style={{
                    width: '100%',
                    textAlign: 'center'
                }}>
                    <div className="slide-btn">
                        <a className="rn-button-style--2 btn-solid" href={`/contact`}>Kontaktieren Sie uns</a>
                    </div>
                </div>

                {/* Start Brand Area */}
                <div className="rn-brand-area bg_color--5 ptb--60">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-12">
                                <BrandMelomate />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Brand Area */}

                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--50 mb_sm--0">
                                    <h3 className="title">Unsere Dienstleistungen</h3>
                                    <p>Ein Einblick in unsere verschiedenen Dienstleistungen</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6">
                                <img className="w-100" src="/assets/images/about/about-4.jpg" alt="About Images"/>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Was wir leisten</h2>
                                        <p>Wir bieten Ihnen Lösungen, die nicht nur effizient, sondern auch smart sind. Gemeinsam definieren wir Wege, den digitalen Fortschritt in Ihr Unternehmen zu bringen und entwickeln passende Software und Anwendungen, die Sie und Ihre Kunden überzeugen werden.</p>
                                    </div>
                                    <div className="accordion-wrapper mt--30">
                                        <AccordionMelomate />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start About Area  */}
                <div className="rn-about-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">Das sind wir</h2>
                                        <p className="description">Melomate ist ein junges und motiviertes Team aus Entwicklern, Designern und Projektmanagern, mit einem großen Netzwerk zu vielen kreativen Köpfen in unterschiedlichen Branchen. Bei uns kommen Ideen und Effizienz zusammen und vereinen sich zu einer fertigen Lösung, die nicht nur Sie, sondern auch Ihre Kunden begeistert.</p>
                                    </div>
                                    <div className="mt--30">
                                        <h4>Was uns ausmacht</h4>
                                        <ul className="list-style--1">
                                            {namesItemOne.map((name, index) => {
                                                return <li key={ index }><FiCheck /> {name}</li>;
                                            })}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="position-relative">
                                    <img className="w-100" src="/assets/images/about/about-melomate.jpg" alt="About Images"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area  */}

                {/* Start Portfolio Area */}
                <div className="portfolio-area ptb--120 bg_color--5">
                    <div className="portfolio-sacousel-inner">
                        <div className="container" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">Unsere Projekte</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    flexDirection: 'row',
                                    justifyContent: 'center'
                                }}>
                                    {this.renderMeloCards(0)}
                                    {this.renderMeloCards(1)}
                                </div>
                            </div>
                            <div className="row">
                                <div style={{
                                    display: 'flex',
                                    flexWrap: 'wrap',
                                    flexDirection: 'row',
                                    justifyContent: 'center'
                                }}>
                                    {this.renderMeloCards(2)}
                                    {this.renderMeloCards(3)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Portfolio Area */}

                {/* Start Footer Style  */}
                <FooterMelomate />
                {/* End Footer Style  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
            </Fragment>
        )
    }
}
export default Melomate;