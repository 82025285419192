import React, { Component } from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';


class AccordionMelomate extends Component {
    render(){
        return(
            <Accordion className="accodion-style--1" preExpanded={'0'}>
                <AccordionItem >
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Analyse
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>Um eine genaue Zielsetzung zu formulieren, setzen wir uns gemeinsam an einen Tisch. Der aktuelle Stand wird erarbeitet und zukünftige Möglichkeiten erörtert. Wir hören Ihnen zu, um so Konkurrenten, die Marktsituation und das Potenzial genau zu erfassen.</p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Planung
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Behalten Sie immer den Überblick – bei uns erhalten Sie vollen Einblick in das Projekt und dessen Planung. Dank agilen Projektmanagements werden effizient die wichtigsten Bedürfnisse zuerst bedient. Über den gesamten Projektzeitraum stehen wir Ihnen zur Verfügung und entwickeln selbstständig oder gemeinsam mit Ihrem Team die Lösungen, die Sie voranbringen.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Optimierung
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Unternehmen und Prozesse müssen in perfekter Synergie funktionieren, auch abteilungsübergreifend. Um Arbeitswege zu sparen und digitale Arbeitstaschen zu ermöglichen, können Verbindungsstellen und passende Anwendungen zu bestehenden Systemen (z. B. ERM) nützlich sein. Wir schauen uns bestehende Prozesse an, beraten Sie zur Vereinfachung und können auf Wunsch passende Programme und Schnittstellen entwickeln.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Corporate Design & User Interface
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Sie wollen, dass Ihr Unternehmen bzw. Ihre Marke so richtig heraussticht und genau Ihre Zielgruppe anspricht? Wir beraten Sie in Fragen der Markenführung und setzen so punktgenau Ihre Marke in Szene. Darüber hinaus sind wir Experten für User Interface Design und erarbeiten Web- und App-Design auf Grundlage von Personas und Benutzeranalysen.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            Entwicklung
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Unser Team an Entwicklern umfasst Erfahrungen in den Bereichen React, .NET, PHP 7, Node.js, Docker und vielen mehr. Moderne Onlineshop-Projekte inklusive Category Management können mit unter anderem Magento 1, Magento 2, WooCommerce und Shopware realisiert werden. Wir integrieren Zahlungssysteme und prüfen diese auf Sicherheitslücken. Um optimale Leistungen zu erzielen, kümmern wir uns um die Serververwaltung und bieten Ihnen somit eine All-in-One-Lösung an.
                        </p>
                        </AccordionItemPanel>
                </AccordionItem>

            </Accordion>
        )
    }
}

export default AccordionMelomate;
